<template>
  <div>
    <Navbar page="Meus Eventos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Evento
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Canal
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Data
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Status
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Nota
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.treinamento && item.treinamento.nome ? item.treinamento.nome : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.treinamento && item.treinamento.transmissao && item.treinamento.transmissao.canalTransmissao ? item.treinamento.transmissao.canalTransmissao : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div v-if="item.treinamento && item.treinamento.dataInicio" class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.treinamento.dataInicio | moment("DD/MM/YYYY HH:mm")}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm font-medium" :class="item.status === 'Finalizado' ? 'text-green-600' : 'text-gray-500'">
                            {{ item.status ? item.status : ''}}
                          </div> 
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div v-if="item.prova && item.prova.resultado" class="flex items-center">
                          <div class="text-sm font-medium" :class="item.prova.resultado.aprovado ? 'text-green-600' : 'text-red-600'">
                            {{ item.prova.resultado.nota }} / {{ item.prova.resultado.total }}
                          </div> 
                        </div>
                        <div v-else-if="item.prova && item.prova.esperandoCorrecao" class="text-sm text-gray-500">
                          Esperando correção
                        </div>
                        <div v-else-if="item.treinamento && item.treinamento.avaliacao" class="text-sm text-gray-500">
                          Avalição não realizada
                        </div>
                      </td>
                      <td class="px-2 py-3 text-sm font-medium">
                          <button v-if="!item.presenca" @click="confirmarPresenca(item._id)" type="button" class="inline-block text-white hover:bg-green-600 bg-green-700 rounded py-1 px-4 mt-2 mx-2">
                              Confirmar presença
                          </button>
                          <router-link v-if="item.treinamento" type="button" :to="`/${route}/info/${item.treinamento._id}?convidado=${item._id}`" class="inline-block text-white hover:bg-blue-500 bg-blue-400 rounded py-1 px-4 mt-2 mx-2">
                              Informações
                          </router-link>
                          <router-link v-if="item.treinamento" type="button" :to="`/${route}/assistir/${item._id}`" class="inline-block text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mt-2 mx-2">
                              {{ item.treinamento && item.treinamento.transmissao && item.treinamento.transmissao.canalTransmissao === 'Presencial' ? 'Visualizar' : 'Assistir' }}
                          </router-link>
                          <button v-if="visualizarOpcaoProva(item)" @click="realizarProva(item)" type="button" class="inline-block text-white hover:bg-blue-600 bg-blue-700 rounded py-1 px-4 mt-2 mx-2">
                            {{ item.prova && item.prova.aplicada ? 'Resultado avaliação' : 'Realizar Avaliação'}}
                          </button>
                          <a v-if="visualizarCertificado(item)" :href="`${api}/certificado/${item._id}`" target="_blank" type="button" class="inline-block text-white hover:bg-indigo-600 bg-indigo-500 rounded py-1 px-4 mt-2 mx-2">
                            Certificado
                          </a>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      route: 'treinamentos',
      list: [],
      selecionados: [],
      page: 1,
      perPage: 10,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/convidados/list`, { skip: this.query.skip, limit: this.query.limit  });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async confirmarPresenca(convidado) {
      const req = await this.$http.post(`/v1/${this.route}/convidados/confirmarPresenca`, { _id: convidado });
       if (req.data.success) {
          this.$vToastify.success("Salvo com sucesso!");
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      } 
    },

    async realizarProva(item) {

      if(item.prova) {
        const req = await this.$http.post(`/v1/avaliacoes/nova-prova`,{ _id: item._id, treinamento: item.treinamento._id  });
        if(req.data.success && req.data.prova) {
          this.$router.push({path:`/treinamentos/answer/${req.data.prova._id}`});
        }else {
          this.$vToastify.error(req.data.err);
        }
        return;
      }

      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja iniciar realização da avaliação ?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
             const req = await this.$http.post(`/v1/avaliacoes/nova-prova`,{ _id: item._id, treinamento: item.treinamento._id  });
              if(req.data.success && req.data.prova) {
                this.$router.push({path:`/treinamentos/answer/${req.data.prova._id}`});
              }else {
                this.$vToastify.error(req.data.err);
              }
            }
          }
      });
    },

    visualizarOpcaoProva(item){
      if(item.prova && item.prova.aplicada) return true;
      if(item.prova && item.prova.prontaPraRealizar) return true;
      if(!item.treinamento) return false;
      if(!item.treinamento.avaliacao) return false;

      if(item.treinamento.envioAvaliacao.tipo === 'Logo após a conclusão do evento') {
        if(moment(item.treinamento.dataFim) < moment()) return true;
        else return false;
      }

      if(item.treinamento.envioAvaliacao.tipo === 'Programar para data específica') {
        if(moment(item.treinamento.envioAvaliacao.data) < moment()) return true;
        else return false;
      }

      if(item.treinamento.envioAvaliacao.tipo === 'Logo após o participante confirmar que assistiu') {
        if(item.presenca) return true;
        else return false;
      }

      return false;
    },

    visualizarCertificado(item){
      
      if(!item.treinamento) return false;
      if(!item.treinamento.certificado) return false;
      if(item.treinamento.certificado.naoIncluiCertificado) return false;
      
      if(item.treinamento.certificado.envioCertificado.tipo === 'Programar para data específica') {
        if(moment(item.treinamento.certificado.envioCertificado.data) < moment()) return true;
        else return false;
      }
      if(item.treinamento.certificado.envioCertificado.tipo === 'Logo após a conclusão do teste') {
        if(item.prova) return true;
        else return false;
      }
      return false
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>